import pino from 'pino';

export default pino({
  timestamp: true,
  transport: {
    target: 'pino-pretty',
  },    
  options: {
    colorize: true
  }
});

