import getConfig from 'next/config';
import * as Sentry from '@sentry/nextjs';

const { publicRuntimeConfig } = getConfig();

const SENTRY_DSN = publicRuntimeConfig?.SENTRY_DSN || process.env.SENTRY_DSN;

const environment = process.env.environment || process.env.NODE_ENV;

Sentry.init({
  debug: false,
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment,
});
