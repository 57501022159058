import { makeVar } from '@apollo/client';
import { useMeQuery, User } from '../graphqlTypes';

const userReactiveVar = makeVar<User>(null);

interface ReturnReactiveVariableUserHook {
  refetchUser: () => Promise<void>;
}

export const useReactiveVariableUser = (): ReturnReactiveVariableUserHook => {
  const { refetch } = useMeQuery({
    fetchPolicy: 'network-only',
    onCompleted: (user): void => {
      if (!user?.me) return;

      userReactiveVar(user.me);
    },
  });

  const refetchUser = async (): Promise<void> => {
    const { data: user } = await refetch();

    userReactiveVar(user?.me);
  };

  return {
    refetchUser,
  };
};

export default userReactiveVar;
