enum ProductTypes {
  book = 'libros',
  model = 'modelos',
  material = 'material',
  poster = 'poster',
  ebook = 'ebooks',
}

export enum ProductTypesWorks {
  book = 'book',
  model = 'model',
  material = 'material',
  poster = 'poster',
  ebook = 'ebook',
}

export enum Language {
  EN = 'Inglés',
  ES = 'Español',
  FR = 'Francés',
  XX = 'Otros',
}

export enum Correlation {
  book = 'libros',
  model = 'modelos-anatomicos-educativos',
  material = 'material-y-suministros-medicos',
  poster = 'poster',
  ebook = 'ebooks',
}

export enum CorrelationShort {
  book = 'libro',
  model = 'modelo',
  material = 'material',
  ebook = 'ebook',
}

export enum CorrelationInverse {
  libros = 'book',
  poster = 'poster',
  ebooks = 'ebook',
  'modelos-anatomicos-educativos' = 'model',
  'material-y-suministros-medicos' = 'material',
}

export enum CorrelationPlural {
  libros = 'libros',
  poster = 'poster',
  ebooks = 'ebooks',
  'modelos-anatomicos-educativos' = 'modelos',
  'material-y-suministros-medicos' = 'material',
}

export default ProductTypes;
