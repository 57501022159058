import getConfig from 'next/config';
import Package from './packageJson';

interface ConfigOptions {
  tagsBookExcluded: string[];
  title: string;
  reCaptcha: string;
  mapsURL: string;
  webURL: string;
  apiURL: string;
  graphQLURL: string;
  hideScrollbars: boolean;
  allProductCategoriesFull: string[];
  allProductCategoriesP: string[];
  pathAttachments: string;
  allProductTypes: string[];
  allProductCategories: string[];
  allLanguage: string[];
  typesSearchs: string[];
  allVersion: string[];
  minWidthSow: number;
  minHeightSow: number;
  allowSuscribe: boolean;
  heightImg: number;
  cartShippingOptions: Record<string, string>[];
  publicKeyStripe: string;
  smtpconfig: { host: string; port: number };
  stateConversion: Record<string, string>;
  isBeta: boolean;
  isProd: boolean;
  gaTrackingID: string;
  binding: Record<string, string>;
  tagManagerId: string;
  minOpenImage: {
    width: number;
    height: number;
  };
  initialPagination: {
    limit: number;
    skip: number;
  };
  noInitialPagination: {
    limit: number;
    skip: number;
  };
  sectionWithAreasOfInterest: string[];
  sectionSpanishWithAreasOfInterest: string[];
  sectionWithNoBooks: string[];
  pixelToLoad: number;
  /** Config to show contrareembolso payment */
  contraReembolsoPaymentOptions: {
    maxTotalToShow: number;
    tax: number;
    maxTotalWithoutTax: number;
  };
  /** Max amount purchase for free shipping */
  maxFreeShipping: number;
  paypal: { merchId: string };
}

export enum SpecialCountriesPostalCodes {
  Baleares = '07',
}

export type NameShippingMethod =
  | 'CORREOS'
  | 'NACEX'
  | 'PLUSPACK EUROPA NACEX'
  | 'EUROBUSINESS PARCEL'
  | 'DHL';

const { publicRuntimeConfig = {} } = getConfig() || {};

export const basicSitemaps = [
  '/sitemap-libros-es',
  '/sitemap-libros-en',
  '/sitemap-libros-xx',
  '/sitemap-modelos',
  '/sitemap-material',
  '/sitemap-other',
  '/sitemap-areas',
  '/sitemap-temas',
  '/sitemap-poster',
];

const options = {} as ConfigOptions;
options.paypal = { merchId: '100785' };

export const useNewPaypal = false;

options.maxFreeShipping = 35;

// Política para contra reembolso. Solo se va a mostrar cuando el total se menor a ${contraReembolsoPaymentOptions.maxTotalWithoutTax} incluyendo la tarifa del contrareembolso
// Es decir la tarifa máxima a mostrar es de ${contraReembolsoPaymentOptions.maxTotalWithoutTax - contraReembolsoPaymentOptions.tax}
options.contraReembolsoPaymentOptions = {
  maxTotalToShow: 0,
  tax: 3,
  maxTotalWithoutTax: 400,
};
options.contraReembolsoPaymentOptions.maxTotalToShow =
  options.contraReembolsoPaymentOptions.maxTotalWithoutTax -
  options.contraReembolsoPaymentOptions.tax;

// Define las cartegorías que se han excluido para mostrar en libros y en ebooks
// según https://app.clickup.com/t/bn0yrg
options.tagsBookExcluded = ['languages', 'authors', 'brands'];
options.sectionWithAreasOfInterest = ['book', 'ebook'];
options.sectionSpanishWithAreasOfInterest = ['libros', 'ebooks'];
options.sectionWithNoBooks = ['model', 'material', 'poster'];
options.pixelToLoad = 1000;

if (process.env.NODE_ENV === 'production') {
  options.title = 'Axon';
  options.reCaptcha = '6LfKhZIUAAAAAJdL6Ux7HnFWZ_tlwmOOv2CGYiPS';
  options.mapsURL = 'AIzaSyAmH-euij9uhP3L8uABvKnrdS-U5GNCYy8';
  options.webURL = 'https://beta.axon.binpar.com';
  options.apiURL = 'https://api.axon.beta.binpar.com';
  options.graphQLURL = 'https://api.axon.beta.binpar.com/graphql';
  options.hideScrollbars = false;
  options.allProductCategoriesFull = [
    'libros',
    'modelos-anatomicos-educativos',
    'material-y-suministros-medicos',
    'revistas',
    'poster',
    'ebooks',
  ];
  options.allProductCategoriesP = ['libros', 'modelos', 'material', 'revistas', 'poster', 'ebooks'];
  options.pathAttachments = 'http://media.axon.es';
  options.allProductTypes = ['book', 'model', 'material', 'poster', 'ebook'];
  options.allProductCategories = ['libro', 'modelo', 'material', 'ebook'];
  options.allLanguage = ['es', 'ge', 'en', 'fr'];
  options.typesSearchs = [
    'brands',
    'authors',
    'tags',
    'availability',
    'languages',
    'saldos-y-ofertas',
  ];
  options.allVersion = ['Papel', 'OnLine'];
  options.minWidthSow = 500;
  options.publicKeyStripe = 'pk_test_EO7gFDZIWG35d2CyQ5ficEVi';
  options.minHeightSow = 500;
  options.heightImg = 250;
  options.allowSuscribe = false;
  options.cartShippingOptions = [
    {
      img: 'https://pics.imgix.net/img/GLS_Logo.png',
      title: 'GLS',
      time: '1 / 2 días',
      price: '0,00 €',
    },
    {
      img: 'https://pics.imgix.net/img/GLS_Logo.png',
      title: 'EUROBUSINESS PARCEL',
      time: '2 / 3 días',
      price: '0,00 €',
    },
    {
      img: 'https://pics.imgix.net/img/nacex.png',
      title: 'NACEX',
      time: '1 / 2 días',
      price: '0,00 €',
    },
    {
      img: 'https://pics.imgix.net/img/nacex.png',
      title: 'PLUSPACK EUROPA NACEX',
      time: '4 / 5 días',
      price: '0,00 €',
    },
    {
      img: 'https://pics.imgix.net/img/correos.svg',
      title: 'CORREOS',
      time: '2 / 3 días',
      price: '0,00 €',
    },
    {
      img: 'https://pics.imgix.net/img/dhl.svg',
      title: 'DHL',
      time: '2 / 3 días',
      price: '0,00 €',
    },
    {
      img: 'https://pics.imgix.net/img/correos_logo.svg',
      title: 'ENVÍO POSTAL',
      time: '10 / 15 días',
      price: '0,00 €',
    },
  ];
  options.smtpconfig = {
    host: 'mail',
    port: 25,
  };
  options.stateConversion = {
    partial: 'Envío múltiple',
    succeeded: 'Completado',
    pending: 'Pendiente',
    cancelled: 'Cancelado',
  };
  options.isBeta = true;
  options.isProd = false;
  options.gaTrackingID = 'UA-1300633-1';
  options.minOpenImage = {
    height: 500,
    width: 500,
  };
  options.initialPagination = {
    limit: 10,
    skip: 0,
  };
  options.noInitialPagination = {
    limit: 40,
    skip: 40,
  };

  const isBetaConst = process.env.isBeta || publicRuntimeConfig?.isBeta;
  if (!isBetaConst || isBetaConst === 'false') {
    options.webURL = 'https://axon.es';
    options.apiURL = 'https://api.axon.es';
    options.graphQLURL = 'https://api.axon.es/graphql';
    options.mapsURL = 'AIzaSyDwcVcvqLBDrBWa_2a48Ra5hB5dD1HYIAw';
    options.publicKeyStripe = 'pk_live_lG9N8vjwuAN8PHJb7yLEf7wr';
    options.isBeta = false;
    options.isProd = true;
    options.reCaptcha = '6LepSpUUAAAAAP-PW5hRjgtwMdKYvpamK187EUai';
    options.tagManagerId = 'GTM-P37NB7';
  }
} else {
  options.mapsURL = 'AIzaSyAmH-euij9uhP3L8uABvKnrdS-U5GNCYy8';
  options.reCaptcha = '6LfKhZIUAAAAAJdL6Ux7HnFWZ_tlwmOOv2CGYiPS';
  options.title = 'Axon';
  options.heightImg = 250;
  options.webURL = 'http://localhost:3000';
  options.apiURL = 'http://localhost:3004';
  options.publicKeyStripe = 'pk_test_EO7gFDZIWG35d2CyQ5ficEVi';
  options.pathAttachments = 'http://media.axon.es';
  options.tagManagerId = 'GTM-W3MNSXS';
  options.typesSearchs = [
    'brands',
    'authors',
    'tags',
    'availability',
    'languages',
    'saldos-y-ofertas',
  ];
  options.allProductCategories = ['libro', 'modelo', 'material', 'ebook'];
  options.allProductCategoriesP = ['libros', 'modelos', 'material', 'revistas', 'poster', 'ebooks'];
  options.allProductCategoriesFull = [
    'libros',
    'modelos-anatomicos-educativos',
    'material-y-suministros-medicos',
    'revistas',
    'poster',
    'ebooks',
  ];
  options.graphQLURL = 'http://localhost:3004/graphql';
  options.minWidthSow = 500;
  options.minHeightSow = 500;
  options.hideScrollbars = false;
  options.isBeta = true;
  options.allowSuscribe = false;
  options.allProductTypes = ['book', 'model', 'material', 'poster', 'ebook'];
  options.allLanguage = ['es', 'ge', 'en', 'fr'];
  options.allVersion = ['Papel', 'OnLine'];
  options.stateConversion = {
    partial: 'Envío múltiple',
    succeeded: 'Completado',
    pending: 'Pendiente',
    cancelled: 'Cancelado',
  };
  options.cartShippingOptions = [
    {
      img: 'https://pics.imgix.net/img/GLS_Logo.png',
      title: 'GLS',
      time: '1 / 2 días',
      price: '0,00 €',
    },
    {
      img: 'https://pics.imgix.net/img/GLS_Logo.png',
      title: 'EUROBUSINESS PARCEL',
      time: '2 / 3 días',
      price: '0,00 €',
    },
    {
      img: 'https://pics.imgix.net/img/nacex.png',
      title: 'NACEX',
      time: '1 / 2 días',
      price: '0,00 €',
    },
    {
      img: 'https://pics.imgix.net/img/nacex.png',
      title: 'PLUSPACK EUROPA NACEX',
      time: '4 / 5 días',
      price: '0,00 €',
    },
    {
      img: 'https://pics.imgix.net/img/correos_logo.svg',
      title: 'CORREOS',
      time: '2 / 3 días',
      price: '0,00 €',
    },
    {
      img: 'https://pics.imgix.net/img/dhl.svg',
      title: 'DHL',
      time: '2 / 3 días',
      price: '0,00 €',
    },
    {
      img: 'https://pics.imgix.net/img/correos_logo.svg',
      title: 'ENVÍO POSTAL',
      time: '10 / 15 días',
      price: '0,00 €',
    },
  ];
  options.minOpenImage = {
    height: 500,
    width: 500,
  };
  options.initialPagination = {
    limit: 10,
    skip: 0,
  };
  options.noInitialPagination = {
    limit: 40,
    skip: 40,
  };
}

options.binding = {
  SOFT: 'Tapa blanda',
  HARD: 'Tapa dura',
  SPIRAL: 'Espiral',
};

export const SCREEN_SIZES = {
  MIN_MOBILE: 320,
  MAX_MOBILE: 736,
  MIN_TABLET: 737,
  MAX_TABLET: 1024,
  MIN_DESK: 1025,
  MAX_DESK: 1440,
  MIN_DESK_HD: 1441,
  MAX_DESK_HD: 1920,
};

export const urlImages = 'https://pics.imgix.net/';

export const currentVersion = Package.version || '2.17.0';

export default options;
