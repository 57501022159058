import { gql, makeVar } from "@apollo/client";
import { positionValues } from "react-custom-scrollbars";

export const getScrollbarValues = gql`
  query getScrollbarValues{
    scrollbarValues @client
  }
`

export const scrollbarValuesVar = makeVar<positionValues>(null);
