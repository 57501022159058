import { isClient } from "./utils";

declare global {
  interface Window {
    dataLayer: DataLayerI[];
  }
} 

interface DataLayerI {
  event: string;
  page?: string | URL;
}

const pageview = (url: string | URL): void => {
  if (isClient()) {
    window?.dataLayer?.push({
      event: 'pageview',
      page: url,
    }) 
  }
}

export default pageview;