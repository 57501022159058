import React, { useEffect } from 'react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { ApolloProvider } from '@apollo/client';

import Head from 'next/head';
import '../public/css/main.css';
import 'react-image-lightbox/style.css';
import { useRouter } from 'next/router';
import Script from 'next/script';
import constants from '../src/utils/constants';
import { analyticsPageView } from '../src/utils/analytics';
import pageview from '../src/utils/gtm';
import favicon from '../public/favicon.ico';
import { useApollo } from '../src/graphql/apollo';

const GTM = constants.tagManagerId;
const GA = constants.gaTrackingID;

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const apolloClient = useApollo(pageProps);
  const router = useRouter();

  useEffect(() => {
    if (constants.isBeta) {
      return null;
    }
    const handleRouteChange = (url: URL): void => {
      analyticsPageView(url);
      pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return (): void => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <link rel="shortcut icon" href={favicon.src} type="image/x-icon" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0, minimal-ui"
        />
      </Head>

      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM}');
          `,
        }}
      />

      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA}`}
      />

      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA}');
          `,
        }}
      />

      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
              (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
              m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
              })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  
            ga('create', '${GA}', 'auto');
            ga('require', 'ec');
          `,
        }}
      />

      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=AW-969076333"
      />

      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-969076333');
          `,
        }}
      />

      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            var oct8ne = document.createElement("script");
            oct8ne.server = "backoffice-eu.oct8ne.com/";
            oct8ne.type = "text/javascript";
            oct8ne.async = true;
            oct8ne.license ="CBAB6A77C896D8CAB16A562FAE6BBF10";
            oct8ne.src = (document.location.protocol == "https:" ? "https://" : "http://") + "static-eu.oct8ne.com/api/v2/oct8ne-api-2.3.js?" + (Math.round(new Date().getTime() / 86400000));
            oct8ne.locale = "es-ES";
            oct8ne.baseUrl ="${
              constants.isProd ? '//axon.es' : '//axon-dos-web-test.binpar.cloud'
            }";
            var s = document.getElementsByTagName("script")[0];
            if (document.cookie.indexOf("oct8ne-room") == -1) {
                  setTimeout(insertOct8ne, 5000);
                  window.addEventListener('mousemove', insertOct8ne);
                  window.addEventListener('scroll', insertOct8ne);
                  window.addEventListener('click', insertOct8ne);
                  window.addEventListener('keydown', insertOct8ne);
                  window.addEventListener('touchstart', insertOct8ne);
            } else {
                  insertOct8ne();
            }
            function insertOct8ne() {
                  if (!window.oct8neScriptInserted) {
                      s.parentNode.insertBefore(oct8ne, s);
                      window.oct8neScriptInserted = true;
                      window.removeEventListener('mousemove', insertOct8ne);
                      window.removeEventListener('scroll', insertOct8ne);
                      window.removeEventListener('click', insertOct8ne);
                      window.removeEventListener('keydown', insertOct8ne);
                      window.removeEventListener('touchstart', insertOct8ne);
                  }
            }
          `,
        }}
      />

      <ApolloProvider client={apolloClient}>
        <Component {...pageProps} />
      </ApolloProvider>
    </>
  );
};

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric): void {
  if (typeof window !== 'undefined' && typeof window?.gtag !== 'undefined')
    window.gtag('event', name, {
      event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value),
      event_label: id,
      non_interaction: true,
    } as Gtag.EventParams);
}

export default MyApp;
