import logger from './logger';
import { Basket, Order, OrderItems } from '../graphqlTypes';
import { buildOtherTaxesAmount, isClient } from './utils';
import constants from './constants';
import { CorrelationShort } from '../models/common.model';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ga?: any;
  }
}
interface Product {
  id: number;
  name?: string;
  category?: string;
  brand?: string;
  price?: number;
  quantity?: number;
  affiliation?: string;
  revenue?: number;
  coupon?: string;
  tax?: number;
  shipping?: number;
  list_name?: string;
}

interface GaInfo {
  id: number;
  revenue?: number;
  tax?: number;
  shipping?: number;
  coupon?: string;
  items?: Product[];
  currency?: string;
}

interface FormatProduct {
  id: number;
  name: string;
  category: string;
  brand: string;
  price: number;
  quantity: number;
}

export const analyticsPageView = (url: URL): void => {
  if (!isClient()) {
    return;
  }
  window.gtag('config', constants.gaTrackingID, {
    page_path: url,
  });
};

export const analyticsEvent = (
  action: Gtag.EventNames,
  { event_category, event_label, value }: Gtag.EventParams,
): void => {
  if (!isClient()) {
    return;
  }
  window.gtag('event', action, {
    event_category,
    event_label,
    value,
  });
};

export const addProduct = (
  id: number,
  name: string,
  category: string,
  brand: string,
  price: number,
  quantity: number,
): Product => {
  try {
    const productFieldObject: Product = {
      id,
      name,
      price,
      quantity,
      list_name: 'Search Results',
    };
    if (category) productFieldObject.category = category;
    if (brand) productFieldObject.brand = brand;
    if (isClient()) {
      // console.log({ productFieldObject });
    }

    return productFieldObject;
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log(`Error adding product ${e?.message}`);
  }

  return null;
};

const addProductsInGa = (
  product?: { id; name; category; brand; price; quantity }[],
): Promise<void> => {
  return new Promise((resolve) => {
    const convertProducts = product.map(({ id, name, category, brand, price, quantity }) => {
      return addProduct(id, name, category, brand, price, quantity);
    });

    for (let indexProduct = 0; indexProduct < convertProducts.length; indexProduct++) {
      const currentProduct = convertProducts[indexProduct];

      window?.ga('ec:addProduct', currentProduct);
    }
    resolve();
  });
};

export const gaCreatePurchase = async ({
  id,
  revenue,
  tax,
  shipping,
  coupon,
  items,
  currency,
}: GaInfo): Promise<void> => {
  try {
    if (!isClient()) {
      return;
    }

    window?.ga('set', 'currencyCode', currency);
    await Promise.all(
      items.map(({ id: pId, name, brand, category, price, quantity }) =>
        addProductsInGa([{ id: pId, name, category, brand, price, quantity }]),
      ),
    );
    const actionFieldObject: Product = {
      id,
      affiliation: 'Editorial Axon',
      revenue,
      tax,
      shipping,
    };

    if (coupon) actionFieldObject.coupon = coupon;

    window.ga('ec:setAction', 'purchase', actionFieldObject);
    window.ga('send', 'pageview');
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log(`Error creando compra analytics ${e?.message}`);
  }
};

export const gaFormatProduct = (product: OrderItems | Basket): FormatProduct => {
  const { book } = product;

  if (!book) {
    return null;
  }

  let category = null;

  if (book.product_type === 'book') {
    category = 'libro';
  } else if (book.product_type === 'ebook') {
    category = 'ebook';
  } else if (book.product_type === 'model') {
    category = 'modelo';
  } else if (book.product_type === 'material') {
    category = 'material';
  }

  const formatProduct = {
    id: book?.id,
    name: book?.title,
    category,
    brand: book?.brand?.name || null,
    price: book?.priceWithDiscount || book?.prices?.sale,
    quantity: product?.units,
  };

  return formatProduct;
};

const gaPurchaseHandler = ({
  orderSequence,
  amount,
  amountTransport,
  amountIVA,
  otherTaxes,
  items,
  giftCardCode,
  discountIdCodeWork,
  discountIdCode,
}: Order): void => {
  try {
    const formatItems = items.map((i) => gaFormatProduct(i));
    const gaInfo: GaInfo = {
      id: orderSequence,
      revenue: amount + amountTransport,
      tax: amountIVA + buildOtherTaxesAmount(otherTaxes),
      shipping: amountTransport,
      coupon: giftCardCode || discountIdCodeWork || discountIdCode,
      items: formatItems,
      currency: 'EUR',
    };
    gaCreatePurchase(gaInfo);
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(`Error gaPurchase ${e?.message}`);
  }
};

export const createCompraConversion = (order: Order): void => {
  try {
    const otherTaxes = buildOtherTaxesAmount(order?.otherTaxes);
    const totalacc = order?.amountWithoutIVA;
    const totalConversion =
      order?.amount + Math.round(order?.amountTransport * 100) / 100 + otherTaxes;
    const recurrencia = 1.87;
    const perdidaTrazabilidad = 1.3;
    const perdidaTrazPaypal = 1.0;
    const gastosEnvio = order?.amountTransport;
    let adwordsMargin = 0;
    const { items } = order;

    items.forEach((item) => {
      const { book } = item;
      const priceBook = order?.discountIdCode
        ? item?.priceSign || item.book?.prices?.sale
        : item?.priceSignDeal || item?.book?.priceWithDiscount;
      const totalCoste = book?.lastBuyPrice * book?.exchangeRate * (book?.buyExpenses / 100 + 1);
      adwordsMargin +=
        item?.units *
        (priceBook - totalCoste - gastosEnvio) *
        recurrencia *
        perdidaTrazabilidad *
        perdidaTrazPaypal;
    });

    const conversion = {
      event: 'compra',
      transactionId: order.orderSequence,
      transactionTotal: totalConversion,
      transactionCurrency: 'EUR',
      transactionNetTotal: totalacc,
      AdwordsMargin: adwordsMargin ? `${Math.round(adwordsMargin)}` : 0,
    };

    gaPurchaseHandler(order);

    if (isClient()) {
      logger.info('PROD sending analytics');
      window?.dataLayer?.push(conversion);
    }
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(`Error enviando datos analytics ${e?.message}`);
  }
};

export const gaCartPayment = (step: number, option: string): void => {
  window?.ga('ec:setAction', 'checkout_option', {
    step,
    option,
  });

  window?.ga('send', 'event', 'checkout', 'option');
};

export const gaCheckoutStep = async (
  step: number,
  productsInBasket: Basket[],
  option?: string,
): Promise<void> => {
  const checkoutOptions: { step: number; option?: string } = {
    step,
  };

  if (option) checkoutOptions.option = option;

  const productGaInfo = productsInBasket.map(({ book: product }) => {
    const category = CorrelationShort[product?.product_type] || 'sin definir';

    return {
      id: product.id,
      name: product.title,
      category,
      brand: product?.brand?.name,
      price: product.priceWithDiscount || product.prices.sale,
      quantity: 1,
    };
  });

  await addProductsInGa(productGaInfo);
  window?.ga('ec:setAction', 'checkout', { ...checkoutOptions });
  window?.ga('send', 'pageview');
};

export const gaCartAction = ({ id, name, category, brand, price, quantity, action }): void => {
  try {
    if (!isClient()) {
      return;
    }
    const product = addProduct(id, name, category, brand, price, quantity);
    let event = '';

    if (action === 'add') {
      event = 'add_to_cart';
    } else if (action === 'remove') {
      event = 'remove_from_cart';
    }
    gtag('event', event, {
      items: [product],
    });
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log(e.message);
  }
};
